import { FlyoutTextButton } from './FlyoutTextButton';
import { formatScore } from './formatScore';
import { useExplainableScore } from './useExplainableScore';
import { useLobbyScoreInfo } from './useLobbyScoreInfo';

export interface WordScoreProps {
  word: string;
  score: number;
}

export const WordScore = ({ score, word }: WordScoreProps) => {
  const lobbyScoreInfo = useLobbyScoreInfo();
  const [, setScore] = useExplainableScore();

  return (
    <FlyoutTextButton onClick={() => setScore({ ...lobbyScoreInfo, word })}>
      {formatScore(score)}
    </FlyoutTextButton>
  );
};
