import { createRoot } from 'react-dom/client';
import { checkBacklog } from './checkBacklog';
import { devError } from './devError';
import { migrateLocalData } from './migrateLocalData';
import { Root } from './Root';

export const init = async () => {
  const root = createRoot(document.getElementById('root')!);

  await migrateLocalData();

  checkBacklog(false).catch(devError);

  root.render(<Root />);
};
