import {
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  ListItem,
  OrderedList,
  Spinner,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { isTruthy } from '@matt-tingen/util';
import { uniq } from 'lodash';
import { useCallback, useDeferredValue, useMemo, useState } from 'react';
import { IoMdCheckmark, IoMdClose } from 'react-icons/io';
import TextareaAutosize from 'react-textarea-autosize';
import { useThemeColor } from './colors';
import { useDate } from './DateProvider';
import { useAsyncDictionary } from './useAsyncDictionary';
import { Word } from './Word';

const parseWords = (text: string) =>
  uniq(text.split(/[^a-z]/gi))
    .filter(isTruthy)
    .map((word) => word.toUpperCase().trim());

export const DictionaryForm = () => {
  const [value, setValue] = useState('');
  const deferredValue = useDeferredValue(value);
  const words = useMemo(() => parseWords(deferredValue), [deferredValue]);

  const dict = useAsyncDictionary(useDate(), words);

  const validColor = useThemeColor('green.600', 'green.500');
  const invalidColor = useThemeColor('red.600', 'red.500');
  const spinnerColor = useThemeColor('gray.400', 'gray.600');

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> =
    useCallback((event) => {
      setValue(event.target.value);
    }, []);

  const handleClear = useCallback(() => {
    setValue('');
  }, []);

  return (
    <Stack spacing={4}>
      <FormControl>
        <FormLabel>Words to Check</FormLabel>
        <Textarea
          as={TextareaAutosize}
          minRows={3}
          value={value}
          onChange={handleChange}
          minHeight="unset"
          resize="none"
        />
      </FormControl>
      {words.length && (
        <OrderedList>
          {words.map((word) => (
            <ListItem key={word} as={HStack}>
              {dict[word] === true && <IoMdCheckmark color={validColor} />}
              {dict[word] === false && <IoMdClose color={invalidColor} />}
              {dict[word] === undefined && (
                <Center boxSize={4}>
                  <Spinner size="xs" color={spinnerColor} />
                </Center>
              )}
              <Word word={word} />
            </ListItem>
          ))}
        </OrderedList>
      )}
      <Button colorScheme="red" variant="outline" onClick={handleClear}>
        Clear
      </Button>
    </Stack>
  );
};
