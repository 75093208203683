import type { IntClosedRange } from 'type-fest';
import { WordPrevalenceData } from './serverOutputs';

export const MAX_PRECADENCE_RANK = 3;
/** Ascending prevalence e.g. 0 = new, 1 = rare, 2 = uncommon, ... */
export type PrevalenceRank = IntClosedRange<0, typeof MAX_PRECADENCE_RANK>;

export const calculatePrevalenceRank = (
  word: string,
  { maxCount, wordCounts }: WordPrevalenceData,
): PrevalenceRank | undefined => {
  const count = wordCounts.get(word);

  if (count === undefined) return undefined;
  if (count === 1) return 0;

  return Math.ceil((count / maxCount) * MAX_PRECADENCE_RANK) as PrevalenceRank;
};
