import { Box } from '@chakra-ui/react';

export interface WordProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}

export const FlyoutTextButton = (
  props: React.ComponentPropsWithoutRef<'button'>,
) => {
  return (
    <Box
      as="button"
      type="button"
      textDecoration="underline"
      textDecorationStyle="dotted"
      {...props}
    />
  );
};
