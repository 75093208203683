import { useCallback } from 'react';
import { useWordInfo } from './useWordInfo';

export const useDefinitionWord = () => {
  const [{ word } = {}, setWordInfo] = useWordInfo();

  const setWord = useCallback(
    (word: string) => setWordInfo((prev) => ({ ...prev, word })),
    [setWordInfo],
  );

  return [word, setWord] as const;
};
