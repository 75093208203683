import { TRPC_URL } from './env';
import { Link } from './Link';
import { Page, PageContents, PageHeader } from './Page';

const TRPC_ORIGIN = new URL(TRPC_URL).origin;

export const AdminPage = () => {
  return (
    <Page>
      <PageHeader>Admin</PageHeader>
      <PageContents>
        <Link to={TRPC_ORIGIN}>tRPC Origin</Link>
      </PageContents>
    </Page>
  );
};
