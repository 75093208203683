import { createStrictStatefulContext } from '@matt-tingen/react-hooks';
import { useState } from 'react';
import { WordPrevalenceData } from './serverOutputs';

export interface WordInfo {
  word: string;
  wordPrevalenceData?: WordPrevalenceData;
}

export const { useWordInfo, WordInfoProvider } = createStrictStatefulContext(
  'WordInfo',
  () => useState<WordInfo | undefined>(),
);
