import {
  Theme,
  ThemeTypings,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';
import { get } from 'lodash';
import type { ConditionalKeys, IsNever } from 'type-fest';

export type ColorDefinition = ThemeTypings['colors'];
export type SaturationColor = ConditionalKeys<Theme['colors'], object>;
export type Saturation = keyof Theme['colors'][SaturationColor];

export const useThemeColor = (
  light: ColorDefinition,
  dark: ColorDefinition,
) => {
  const { colors } = useTheme();

  return useColorModeValue(get(colors, light), get(colors, dark)) as string;
};

export const saturations = [
  50, 100, 200, 300, 400, 500, 600, 700, 800, 900,
] satisfies Saturation[];

true satisfies IsNever<Exclude<Saturation, (typeof saturations)[number]>>;

export const useBodyColor = () => useThemeColor('gray.800', 'whiteAlpha.900');
export const useBodyBackground = () => useThemeColor('white', 'gray.800');
