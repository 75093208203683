import { useHandler } from '@matt-tingen/react-hooks';
import { useEffect } from 'react';

export const useWindowBlur = (onBlur: () => void, enable = true) => {
  const handler = useHandler(onBlur);

  useEffect(() => {
    if (enable) {
      const handleVisibilityChange = () => {
        if (document.hidden) {
          handler();
        }
      };

      handleVisibilityChange();

      document.addEventListener('visibilitychange', handleVisibilityChange);
      document.addEventListener('blur', handler);

      return () => {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
        document.removeEventListener('blur', handler);
      };
    }
  }, [enable, handler]);
};
