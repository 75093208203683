import { randomId } from './randomId';

const getLocalStorageConstant = (key: string, factory: () => string) => {
  const storedValue = localStorage.getItem(key);
  const value = storedValue || factory();

  if (!storedValue) {
    localStorage.setItem(key, value);
  }

  return value;
};

export const USER_ID = getLocalStorageConstant('user-id', randomId);
export const CLIENT_SECRET = getLocalStorageConstant('client-secret', randomId);

export const useUserId = () => USER_ID;
