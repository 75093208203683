import { createJsonStorage } from './createJsonStorage';
import { migrations } from './migrations';

const [loadVersion, saveVersion] = createJsonStorage('local-data-version', 0);

export const migrateLocalData = async () => {
  let version = loadVersion();

  const migrationsToRun = migrations.slice(version);

  for (const migration of migrationsToRun) {
    // eslint-disable-next-line no-await-in-loop -- migrations must be run in sequence.
    await migration();
    saveVersion(++version);
  }
};
