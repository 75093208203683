import { createStrictStatefulContext } from '@matt-tingen/react-hooks';
import { useState } from 'react';
import { LobbyScoreInfo } from './useLobbyScoreInfo';

export interface ExplainableScore extends LobbyScoreInfo {
  word: string;
}

export const { ExplainableScoreProvider, useExplainableScore } =
  createStrictStatefulContext('ExplainableScore', () =>
    useState<ExplainableScore>(),
  );
