import { WordInfoProvider } from './useWordInfo';
import { Children } from './util';
import { WordInfoDrawer } from './WordInfoDrawer';

export const WordInfoWrapper = ({ children }: Children) => {
  return (
    <WordInfoProvider>
      {children}
      <WordInfoDrawer />
    </WordInfoProvider>
  );
};
