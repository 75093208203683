import { Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useMemo, useState } from 'react';
import { useThemeColor } from './colors';
import { usePrefersReducedMotion } from './usePrefersReducedMotion';

const fallAndFade = keyframes({
  from: {
    transform: 'translateY(0%)',
    opacity: '100%',
  },
  to: {
    transform: 'translateY(30px)',
    opacity: '0%',
  },
});

const AnimatedIncrement = styled(Box)({
  animation: `${fallAndFade} 1s ease forwards`,
});

export type Increment = string | { value: number; label: string };

export interface IncrementDisplayProps {
  increments: Increment[];
}

export const IncrementDisplay = memo(
  ({ increments: allIncrements }: IncrementDisplayProps) => {
    const prefersReducedMotion = usePrefersReducedMotion();
    const invalidColor = useThemeColor('red.600', 'red.500');
    const [startIndex, setStartIndex] = useState(allIncrements.length);
    const increments = useMemo(
      () => allIncrements.slice(startIndex),
      [allIncrements, startIndex],
    );

    return (
      <Box position="relative" width="100%">
        <Box
          position="absolute"
          width="100%"
          display="grid"
          gridTemplateAreas='"inc"'
          fontWeight="bold"
        >
          {!prefersReducedMotion &&
            increments.map((increment, offset) => {
              const index = startIndex + offset;

              return (
                <AnimatedIncrement
                  key={index}
                  gridArea="inc"
                  color={
                    typeof increment === 'object' && increment.value < 0
                      ? invalidColor
                      : undefined
                  }
                  onAnimationEnd={() => {
                    setStartIndex(index + 1);
                  }}
                >
                  {typeof increment === 'object' ? increment.label : increment}
                </AnimatedIncrement>
              );
            })}
        </Box>
      </Box>
    );
  },
);
