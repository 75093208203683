import {
  Badge,
  Heading,
  HStack,
  Stack,
  StackDivider,
  StackItem,
  StatGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProps,
} from '@chakra-ui/react';
import { SomeRequired } from '@matt-tingen/util';
import { useMemo } from 'react';
import { ScorablePlay, scoreLobby, ScoringMethodology } from 'shared';
import { CommentsSection } from './CommentsSection';
import { GroupScore } from './GroupScore';
import { GroupWordCount } from './GroupWordCount';
import { HighScoreList } from './HighScoreList';
import { PlaysBreakdown } from './PlaysBreakdown';
import { WordPrevalenceData } from './serverOutputs';
import { trpc } from './trpc';
import { LobbyScoreInfo, LobbyScoreInfoProvider } from './useLobbyScoreInfo';

export interface LeaderboardProps
  extends SomeRequired<Omit<TabsProps, 'children'>, 'index' | 'onChange'> {
  date: Date;
  plays: ScorablePlay[];
  wordPrevalenceData: WordPrevalenceData;
  methodology: ScoringMethodology;
}

export const Leaderboard = ({
  date,
  plays,
  wordPrevalenceData,
  methodology,
  ...tabsProps
}: LeaderboardProps) => {
  const lobbyScoreInfo = useMemo<LobbyScoreInfo>(
    () => ({ methodology, plays }),
    [methodology, plays],
  );
  const scoredLobby = useMemo(
    () => scoreLobby(methodology, plays),
    [methodology, plays],
  );

  const { data: comments } = trpc.comment.list.useQuery(date);

  return (
    <Tabs
      isFitted
      {...tabsProps}
      onChange={(i) => {
        tabsProps.onChange(i);
      }}
    >
      <TabList>
        <Tab>Scores</Tab>
        <Tab>Words</Tab>
        <Tab>
          <HStack>
            <StackItem>Comments</StackItem>
            {comments?.length ? (
              <Badge variant="outline">{comments.length}</Badge>
            ) : null}
          </HStack>
        </Tab>
      </TabList>
      <TabPanels
        sx={{
          '& > *': {
            px: [2, 4],
          },
        }}
      >
        <TabPanel>
          <Stack spacing={4} divider={<StackDivider />}>
            <Stack spacing={2}>
              <Heading as="h3" size="sm">
                Group Scores
              </Heading>
              <StatGroup>
                <GroupScore score={scoredLobby.aggregateScore} />
                <GroupWordCount count={scoredLobby.wordScores.size} />
              </StatGroup>
            </Stack>
            <Stack spacing={2}>
              <Heading as="h3" size="sm">
                Individual Scores
              </Heading>
              <HighScoreList plays={scoredLobby.plays} />
            </Stack>
          </Stack>
        </TabPanel>
        <TabPanel>
          <LobbyScoreInfoProvider value={lobbyScoreInfo}>
            <PlaysBreakdown
              scoredLobby={scoredLobby}
              wordPrevalenceData={wordPrevalenceData}
            />
          </LobbyScoreInfoProvider>
        </TabPanel>
        <TabPanel>
          <CommentsSection date={date} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
