import { useLocalStorageValue } from '@react-hookz/web';
import { INITIAL_GAME_TIME_MS } from './constants';
import { useDate } from './DateProvider';
import { getDailyStorageKey } from './getDailyStorageKey';

export const useCurrentDailyStatus = () => {
  const date = useDate();
  const key = getDailyStorageKey(date);

  const [savedTimer] = useLocalStorageValue(key('timer'), INITIAL_GAME_TIME_MS);
  const [foundWords] = useLocalStorageValue<string[]>(key('found'), []);

  const hasStarted =
    savedTimer !== INITIAL_GAME_TIME_MS || foundWords.length > 0;
  const isDone = savedTimer === 0;

  return { hasStarted, isDone };
};
