import { z } from 'zod';
import { MAX_COMMENT_LENGTH } from './constants';

// const board = z.string().regex(/^([A-Z]{16}|[A-Z]{25})$/);
const boardDate = z.date();
const word = z.string().regex(/^[A-Z]{3,}$/);
const color = z.string().regex(/^#[a-f\d]{6}$/i);

export const boardDateSchema = boardDate;

// #region changeset
export const changesetListSchema = z.object({
  asOf: boardDate,
  after: z.date().optional(),
});

export const changesetCreateSchema = z.object({
  password: z.string(),
  hidden: z.boolean(),
  content: z.string(),
  additions: z.array(word),
  removals: z.array(word),
  effectiveDate: boardDate,
});
// #endregion

// #region comment
export const commentCreateSchema = z.object({
  date: boardDate,
  message: z.string().min(1).max(MAX_COMMENT_LENGTH),
});
// #endregion

// #region play
export const playBulkCreateSchema = z.object({
  plays: z.array(
    z.object({
      date: boardDate,
      found: z.array(word),
      missed: z.array(word),
    }),
  ),
});

export const scoreSchema = z.object({
  score: z.number(),
  found: z.array(word),
  missed: z.array(word),
});

export const playCreateSchema = z.object({
  date: boardDate,
  score: scoreSchema,
});

export const playDeleteSchema = z.object({
  date: boardDate,
});
// #endregion

// #region user
export const userCustomizeSchema = z.object({
  displayName: z.string(),
  color: color.optional(),
});
// #endregion

// #region dictionary
export const dictionaryCheckSchema = z.object({
  date: boardDateSchema,
  words: z.array(z.string()),
});
// #endregion
