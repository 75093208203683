import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { ExplainableScoreDisplay } from './ExplainableScoreDisplay';
import { useExplainableScore } from './useExplainableScore';

export const ScoreDrawer = () => {
  const [explainableScore, setScore] = useExplainableScore();

  return (
    <Drawer
      isOpen={Boolean(explainableScore)}
      onClose={() => setScore(undefined)}
      placement="bottom"
    >
      <DrawerOverlay />
      {explainableScore && (
        <DrawerContent maxH="75vh">
          <DrawerCloseButton />
          <DrawerHeader>Scoring</DrawerHeader>
          <DrawerBody>
            <ExplainableScoreDisplay {...explainableScore} />
          </DrawerBody>
        </DrawerContent>
      )}
    </Drawer>
  );
};
