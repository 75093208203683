import { useToast } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { formatDate, ScorablePlay } from 'shared';
import { useLocation } from 'wouter';
import { checkBacklog } from './checkBacklog';
import { INITIAL_GAME_TIME_MS } from './constants';
import { useDate, useLocalDate } from './DateProvider';
import { CompleteGameData, Game } from './Game';
import { getDailyStorageKey } from './getDailyStorageKey';
import { setLastCompletedDate } from './lastCompletedDate';
import { PageSpinner } from './PageSpinner';
import { resetDaily } from './resetDaily';
import { getShareText, ShareData } from './share';
import { trpc } from './trpc';
import { useLocalGameState } from './useLocalGameState';
import { useNickname } from './useNickname';
import { usePrefetchBoard } from './usePrefetchBoard';
import { useCrossTabLock } from './useSingleTabLock';
import { useUserId } from './useUserId';

export const DailyPage = () => {
  const userId = useUserId();
  const date = useDate();
  const dateString = formatDate(date);
  const localDate = useLocalDate();
  const toast = useToast();

  const { data: { board, methodology } = {} } =
    trpc.dailyBoard.find.useQuery(date);
  const { data: dictionary } = trpc.solution.find.useQuery(date);

  const handleShare = useCallback(
    async (data: Omit<ShareData, 'date'>) => {
      const text = getShareText({ ...data, date: localDate });

      await navigator.clipboard.writeText(text);

      toast({
        title: 'Results copied to your clipboard!',
        duration: 3000,
      });
    },
    [localDate, toast],
  );

  const queryClient = useQueryClient();
  const [userName] = useNickname();

  const scoreMutation = trpc.play.create.useMutation({
    onSuccess: async (data, { date }) => {
      checkBacklog(true);

      await queryClient.invalidateQueries({
        queryKey: trpc.play.list.getQueryKey(date),
      });
    },
    onMutate: async ({ date, score: { score: _, ...score } }) => {
      setLastCompletedDate(dateString);
      const queryKey = trpc.play.list.getQueryKey(date);

      await queryClient.cancelQueries({ queryKey });
      queryClient.setQueryData<ScorablePlay[]>(queryKey, (prev) => [
        ...(prev ?? []),
        { ...score, user: { id: userId, displayName: userName } },
      ]);
    },
  });

  const handleComplete = useCallback(
    (data: CompleteGameData) => {
      scoreMutation.mutate({
        date,
        score: data,
      });
    },
    [date, scoreMutation],
  );

  const [, setLocation] = useLocation();
  const navigateToReview = useCallback(
    () => setLocation(`/review/${dateString}`),
    [dateString, setLocation],
  );

  const [gameState, gameStateUpdaters] = useLocalGameState(
    getDailyStorageKey(dateString),
    INITIAL_GAME_TIME_MS,
  );

  // Prefetch leaderboard once game as game nears completion.
  usePrefetchBoard(gameState.timer < 10_000 ? date : undefined);

  useCrossTabLock('game-page-lock', () => {
    setLocation('/');
  });

  const isDone = gameState.timer === 0;
  const reset = useCallback(() => resetDaily(date, isDone), [date, isDone]);

  useEffect(() => {
    const win = window as unknown as { reset?: () => void };

    win.reset = reset;

    return () => {
      delete win.reset;
    };
  }, [date, isDone, reset]);

  return dictionary && board && methodology ? (
    <Game
      {...gameState}
      {...gameStateUpdaters}
      board={board}
      dictionary={dictionary}
      incrementalTimeMs={4_000}
      minWordLength={3}
      scoringMethodology={methodology}
      onComplete={handleComplete}
      onLeaderboardClick={navigateToReview}
      onShare={handleShare}
      onReset={reset}
    />
  ) : (
    <PageSpinner />
  );
};
