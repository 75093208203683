import { QueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { User } from 'shared';
import { TrpcOutputs } from './serverOutputs';
import { trpc } from './trpc';

export const updateUserInCache = (
  queryClient: QueryClient,
  { id, displayName, color }: User,
) => {
  const updateUser = <T extends { user: User }>(item: T): T =>
    item.user.id === id
      ? {
          ...item,
          user: { ...item.user, displayName, color },
        }
      : item;

  queryClient.setQueriesData<TrpcOutputs['comment']['list']>(
    { queryKey: getQueryKey(trpc.comment.list) },
    (data) => data?.map(updateUser),
  );

  queryClient.setQueriesData<TrpcOutputs['play']['list']>(
    { queryKey: getQueryKey(trpc.play.list) },
    (data) =>
      data && {
        ...data,
        plays: data?.plays.map(updateUser),
      },
  );
};
