import { createStrictContext } from '@matt-tingen/react-hooks';
import { ScorablePlay, ScoringMethodology } from 'shared';

export interface LobbyScoreInfo {
  methodology: ScoringMethodology;
  plays: ScorablePlay[];
}

export const { LobbyScoreInfoProvider, useLobbyScoreInfo } =
  createStrictContext<LobbyScoreInfo>()('LobbyScoreInfo');
