import { HStack, IconButton, Stack, Text, Textarea } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { IoMdSend } from 'react-icons/io';
import TextareaAutosize from 'react-textarea-autosize';
import { MAX_COMMENT_LENGTH } from 'shared';
import { useThemeColor } from './colors';
import { randomId } from './randomId';
import { Comment } from './serverOutputs';
import { trpc } from './trpc';
import { useNickname } from './useNickname';
import { useUserColor } from './useUserColor';
import { useUserId } from './useUserId';

const CHARS_REMAINING_THRESHOLD = Math.round(MAX_COMMENT_LENGTH / 20);

export interface CommentFormProps {
  date: Date;
}

export const CommentForm = ({ date }: CommentFormProps) => {
  const userId = useUserId();
  const [userName] = useNickname();
  const [color] = useUserColor();
  const [message, setMessage] = useState('');

  const charsRemainingColor = useThemeColor('gray.500', 'gray.400');
  const charsRemaining = MAX_COMMENT_LENGTH - message.length;

  const queryClient = useQueryClient();
  const queryKey = useMemo(() => trpc.comment.list.getQueryKey(date), [date]);
  const commentQuery = trpc.comment.create.useMutation({
    onMutate: async ({ message }) => {
      await queryClient.cancelQueries({ queryKey });

      const prevComments = queryClient.getQueryData<Comment[]>(queryKey);

      queryClient.setQueryData<Comment[]>(queryKey, (prev) => [
        ...(prev ?? []),
        {
          id: randomId(),
          createdAt: new Date(),
          message,
          user: { id: userId, displayName: userName, color },
        },
      ]);

      return { prevComments };
    },
    onError: (err, data, context) => {
      if (context) {
        queryClient.setQueryData<Comment[]>(queryKey, context.prevComments);
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey });
    },
  });

  return (
    <HStack
      as="form"
      align="flex-end"
      onSubmit={(event) => {
        event.preventDefault();

        if (message) {
          commentQuery.mutate({ message, date });
          setMessage('');
        }
      }}
    >
      <Textarea
        as={TextareaAutosize}
        minRows={1}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        maxLength={MAX_COMMENT_LENGTH}
        autoCapitalize="sentences"
        enterKeyHint="send"
        minHeight="unset"
        resize="none"
      />
      <Stack align="center" spacing={1}>
        <IconButton
          type="submit"
          icon={<IoMdSend />}
          aria-label="send comment"
          colorScheme="blue"
          isDisabled={!message}
        />
        {charsRemaining <= CHARS_REMAINING_THRESHOLD && (
          <Text fontSize="xs" color={charsRemainingColor}>
            {charsRemaining}
          </Text>
        )}
      </Stack>
    </HStack>
  );
};
