import { Box, BoxProps, Center } from '@chakra-ui/react';
import { useThemeColor } from './colors';
import { titleCase } from './util';

export interface MiniGameBoardProps extends BoxProps {
  board: string[];
}

export const MiniGameBoard = ({ board, ...props }: MiniGameBoardProps) => {
  const size = Math.sqrt(board.length);
  const scale = 4 / size;
  const color = useThemeColor('gray.800', 'whiteAlpha.800');

  return (
    <Box
      display="grid"
      gridTemplateRows={`repeat(${size}, minmax(0, 1fr))`}
      gridTemplateColumns={`repeat(${size}, minmax(0, 1fr))`}
      gap="4px"
      transformOrigin="center center"
      transform={`scale(${scale})`}
      sx={{
        '--width': `${90 / scale}px`,
        '--height': `${90 / scale}px`,
        '--scale': scale.toString(),
        '--m': 'calc(-1 * (1 - var(--scale)) / 2)',
        width: 'var(--width)',
        height: 'var(--height)',
        transform: 'scale(var(--scale))',
        // Compensate for `tranform` not affecting layout.
        margin: 'calc(var(--width) * var(--m)) calc(var(--height) * var(--m));',
      }}
      userSelect="none"
      {...props}
    >
      {board.map((die, i) => (
        <Center
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          color={color}
          border="0.5px solid"
          borderRadius="2px"
          fontSize="0.8em"
        >
          {titleCase(die)}
        </Center>
      ))}
    </Box>
  );
};
