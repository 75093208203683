import { range } from 'lodash';
import { getDailyStorageKey } from './getDailyStorageKey';
import { trpcClient } from './trpc';

export const resetDaily = async (date: Date, isDone: boolean) => {
  const keyPrefix = getDailyStorageKey(date)('');
  const allKeys = range(localStorage.length).map((i) => localStorage.key(i)!);
  const gameKeys = allKeys.filter((k) => k.startsWith(keyPrefix));

  gameKeys.forEach((k) => {
    localStorage.removeItem(k);
  });

  if (isDone) {
    try {
      await trpcClient.play.delete.mutate({ date });
    } catch {
      // no-op
    }
  }

  window.location.href = '/';
  window.location.reload();
};
