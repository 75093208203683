import { Box } from '@chakra-ui/react';
import { IoMdRibbon } from 'react-icons/io';
import arc, { ArcConfig } from 'svg-arc';
import {
  calculatePrevalenceRank,
  PrevalenceRank,
} from './calculatePrevalenceRank';
import { useThemeColor } from './colors';
import { WordPrevalenceData } from './serverOutputs';

export interface PrevalenceIndicatorProps {
  word: string;
  wordPrevalenceData: WordPrevalenceData;
}

export const PrevalenceIndicator = ({
  word,
  wordPrevalenceData,
}: PrevalenceIndicatorProps) => {
  const newColor = useThemeColor('green.400', 'green.600');
  const rareColor = useThemeColor('yellow.500', 'yellow.500');
  const uncommonColor = useThemeColor('orange.500', 'orange.600');
  const commonColor = useThemeColor('red.500', 'red.600');

  const rank = calculatePrevalenceRank(word, wordPrevalenceData);

  if (rank === undefined) return null;
  if (rank === 0) return <Box as={IoMdRibbon} color={newColor} />;

  const fillPortion =
    wordPrevalenceData.wordCounts.get(word)! / wordPrevalenceData.maxCount;

  const color = (
    {
      1: rareColor,
      2: uncommonColor,
      3: commonColor,
    } satisfies Record<Exclude<PrevalenceRank, 0>, string>
  )[rank];

  const config = {
    x: 50,
    y: 50,
    start: 0,
  } satisfies Partial<ArcConfig>;

  return (
    <Box
      as="svg"
      viewBox="0 0 100 100"
      width={4}
      height={4}
      color={color}
      opacity={0.7}
    >
      <path
        d={arc({ ...config, r: 45, end: 360 * fillPortion })}
        fill="currentColor"
      />
      <path
        d={arc({ ...config, R: 50, r: 40, end: 360 })}
        fill="currentColor"
        fillRule="evenodd"
      />
    </Box>
  );
};
