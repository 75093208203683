import { OrderedList, Stack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { formatScore } from './formatScore';
import { ExplainableScore } from './useExplainableScore';

const percentFormat = new Intl.NumberFormat(undefined, {
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});
const verboseScoreFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 4,
  minimumFractionDigits: 0,
});

const pluralize = (count: number, word: string, plural = `${word}s`) =>
  count === 1 ? word : plural;

export const ScoreExplanation = ({
  methodology: { prorated, wordLengthScores },
  plays,
  word,
}: ExplainableScore) => {
  const baseScore =
    wordLengthScores[word.length - 3] ?? wordLengthScores.at(-1)!;

  const foundCount = useMemo(
    () => plays.filter((play) => play.found.includes(word)).length,
    [plays, word],
  );
  const totalPlayers = plays.length;
  const othersMissedCount = totalPlayers - foundCount;
  const portion = othersMissedCount / (totalPlayers - 1);
  const overallScore = baseScore * portion;

  const thisWord = <>“{word}”</>;

  const methodologyExplanation = prorated ? (
    <p>
      This board uses prorated scoring so words are worth a portion of their
      base score depending on how many players find the word.
    </p>
  ) : (
    <p>
      This board uses traditional scoring so words are worth their full base
      score if just one player finds the word and they are worth no points if
      multiple players find the word
    </p>
  );

  if (!prorated) {
    return foundCount === 1 ? (
      <p>
        Because only one player found {thisWord}, it is worth {baseScore}{' '}
        {pluralize(baseScore, 'point')} based on its length.
      </p>
    ) : (
      <p>
        Because multiple players found {thisWord}, it is not worth any points.
      </p>
    );
  }

  const values = (
    <OrderedList listStyleType="none">
      <li>
        {thisWord} has {word.length} letters so it has a base score of{' '}
        {baseScore} {pluralize(baseScore, 'point')}.
      </li>
      {totalPlayers === 1 ? (
        <li>
          Since only 1 player has played this board, they get the full points.
        </li>
      ) : (
        <>
          <li>
            {foundCount} out of {totalPlayers}{' '}
            {pluralize(totalPlayers, 'player')} found {thisWord}.
          </li>
          <li>
            From the perspective of a player who found {thisWord},{' '}
            {othersMissedCount} of the <i>other</i> {totalPlayers - 1}{' '}
            {pluralize(totalPlayers - 1, 'player')} (
            {percentFormat.format(othersMissedCount / (totalPlayers - 1))}) did
            not find {thisWord}.
          </li>
          <li>
            The overall score is the base score multiplied by this percentage:{' '}
            {baseScore} &times;{' '}
            {percentFormat.format(othersMissedCount / (totalPlayers - 1))} ={' '}
            {verboseScoreFormat.format(overallScore)}
          </li>
          {formatScore(overallScore) !==
            verboseScoreFormat.format(overallScore) && (
            <li>
              This rounds to {formatScore(overallScore)} which is what is
              displayed in the word list.
            </li>
          )}
        </>
      )}
    </OrderedList>
  );

  return (
    <Stack gap={2}>
      {methodologyExplanation}
      {values}
    </Stack>
  );
};
