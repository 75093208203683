import { useMediaQuery } from '@react-hookz/web';
import { trpc } from './trpc';

export const usePrefetchBoard = (date: Date | undefined) => {
  const prefersReducedData = useMediaQuery('(prefers-reduced-data: reduce');
  const options = {
    enabled: Boolean(date && !prefersReducedData),
    notifyOnChangeProps: [],
  };

  trpc.dailyBoard.find.useQuery(date!, options);
  trpc.comment.list.useQuery(date!, options);
  trpc.play.list.useQuery(date!, options);
};
