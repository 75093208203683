import { DictionaryForm } from './DictionaryForm';
import { Page, PageContents, PageHeader } from './Page';

export const DictionaryPage = () => {
  return (
    <Page>
      <PageHeader>Dictionary</PageHeader>
      <PageContents>
        <DictionaryForm />
      </PageContents>
    </Page>
  );
};
