import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { User } from 'shared';
import { randomId } from './randomId';
import { trpc } from './trpc';
import { updateUserInCache } from './updateUserInCache';
import { useNickname } from './useNickname';
import { useOnce } from './useOnce';
import { UserCustomizationForm } from './UserCustomizationForm';
import { useUserColor } from './useUserColor';
import { useUserId } from './useUserId';

export interface UserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const HEADER_ID = 'user-modal-header';

export const UserModal = ({ isOpen, onClose }: UserModalProps) => {
  const userId = useUserId();
  const [, setSavedUserName] = useNickname();
  const [, setSavedColor] = useUserColor();

  const queryClient = useQueryClient();
  const { mutate, isLoading } = trpc.user.customize.useMutation({
    onSuccess: (data, user) => {
      setSavedUserName(user.displayName);
      setSavedColor(user.color ?? null);
      updateUserInCache(queryClient, { ...user, id: userId });
      onClose();
    },
  });

  const [formKey, setFormKey] = useState(randomId);
  const formId = useOnce(() => `user-form-${formKey}`);

  const onSubmit = useCallback(
    (user: User) => {
      mutate({
        ...user,
        color: user.color ?? undefined,
      });
    },
    [mutate],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => setFormKey(randomId())}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader id={HEADER_ID}>Customize User Display</ModalHeader>
        <ModalCloseButton />

        <ModalBody display="flex" flexDir="column" gap={4}>
          <UserCustomizationForm
            key={formKey}
            id={formId}
            aria-labelledby={HEADER_ID}
            onSubmit={onSubmit}
          />
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" onClick={onClose} mr={2}>
            Cancel
          </Button>
          <Button
            type="submit"
            colorScheme="blue"
            form={formId}
            disabled={isLoading}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
