import { Center, Spinner, Stack } from '@chakra-ui/react';
import { isSameDay, subDays } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { DateString, formatDate, parseDate } from 'shared';
import { useLocation } from 'wouter';
import { DateControl } from './DateControl';
import { useDate } from './DateProvider';
import { Leaderboard } from './Leaderboard';
import { trpc } from './trpc';
import { usePrefetchBoard } from './usePrefetchBoard';

export interface DailyReviewProps {
  date: DateString;
}

export const DailyReview = ({ date: dateString }: DailyReviewProps) => {
  const today = useDate();
  const date = useMemo(() => parseDate(dateString), [dateString]);
  const isToday = useMemo(() => isSameDay(today, date), [date, today]);

  const [, setLocation] = useLocation();

  const setDate = useCallback(
    (newDate: Date) => {
      setLocation(`/review/${formatDate(newDate)}`, { replace: true });
    },
    [setLocation],
  );

  const [tabIndex, setTabIndex] = useState(0);

  const dailyQuery = trpc.dailyBoard.find.useQuery(date);
  const playsQuery = trpc.play.list.useQuery(date, {
    staleTime: isToday ? 0 : Infinity,
  });

  // Prefetch comments
  usePrefetchBoard(date);

  // Prefetch prior day's data
  usePrefetchBoard(useMemo(() => subDays(date, 1), [date]));

  return (
    <Stack spacing={4} justifyContent="center">
      <DateControl value={date} onChange={setDate} />
      {playsQuery.isSuccess && dailyQuery.isSuccess ? (
        <Leaderboard
          date={date}
          plays={playsQuery.data.plays}
          wordPrevalenceData={playsQuery.data.words}
          methodology={dailyQuery.data.methodology}
          index={tabIndex}
          onChange={setTabIndex}
        />
      ) : (
        <Center minH={100}>
          <Spinner />
        </Center>
      )}
    </Stack>
  );
};
