import { filterEntries, isNonNullish, mapValues } from '@matt-tingen/util';
import { memoize, range } from 'lodash';

const getNeighbors = (width: number, height: number) => {
  const count = width * height;

  return Object.fromEntries(
    range(count).map((i) => {
      const row = Math.floor(i / width);
      const col = i % height;

      const n = row - 1;
      const s = row + 1;
      const e = col + 1;
      const w = col - 1;

      const neighbors = mapValues(
        filterEntries(
          {
            north: [n, col],
            northEast: [n, e],
            east: [row, e],
            southEast: [s, e],
            south: [s, col],
            southWest: [s, w],
            west: [row, w],
            northWest: [n, w],
          } as const,
          ([r, c]) => r >= 0 && r < height && c >= 0 && c < width,
        ),
        (coords) => {
          const [r, c] = coords!;

          return r * width + c;
        },
      );

      const cardinals = new Set(
        [
          neighbors.north,
          neighbors.east,
          neighbors.south,
          neighbors.west,
        ].filter(isNonNullish),
      );
      const diagonals = new Set(
        [
          neighbors.northEast,
          neighbors.southEast,
          neighbors.southWest,
          neighbors.northWest,
        ].filter(isNonNullish),
      );
      const all = new Set([...cardinals, ...diagonals]);

      return [
        i,
        {
          all,
          cardinals,
          diagonals,
          compass: neighbors,
          edges: {
            north: row === 0,
            east: col === width - 1,
            south: row === height - 1,
            west: col === 0,
          },
        },
      ];
    }),
  );
};

export const getNeighboringIndices = memoize((gridSize: number) =>
  getNeighbors(gridSize, gridSize),
);
