import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import { isTruthy } from '@matt-tingen/util';
import { identity, orderBy, range } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import {
  FiPauseCircle,
  FiPlayCircle,
  FiRotateCcw,
  FiRotateCw,
} from 'react-icons/fi';
import { IoMdShare } from 'react-icons/io';
import {
  getStrikeTimePenalty,
  scoreSolo,
  scoreSoloWord,
  ScoringMethodology,
} from 'shared';
import { ColorModeToggle } from './ColorModeToggle';
import { useBodyBackground, useThemeColor } from './colors';
import { formatDuration } from './formatDuration';
import { formatTimeIncrement } from './formatTimeIncrement';
import { GameBoard } from './GameBoard';
import { GameState, GameStateUpdaters } from './GameState';
import { useGoofiness } from './goofiness';
import { IncrementDisplay } from './IncrementDisplay';
import { isExemptMiss } from './isExemptMiss';
import { NextGame } from './NextGame';
import { PauseCover } from './PauseCover';
import { ShareData } from './share';
import { StrikeIndicator } from './StrikeIndicator';
import { useIsTouchDevice } from './useIsTouchDevice';
import { useSecondClickConfirm } from './useSecondClickConfirm';
import { useTimer } from './useTimer';
import { useWindowBlur } from './useWindowBlur';
import { WordList, WordListItem } from './WordList';
import { getWordStatus, useWordStatusColors, WordStatus } from './wordStatus';

export interface CompleteGameData {
  score: number;
  found: string[];
  missed: string[];
}

interface Props extends GameState, GameStateUpdaters {
  board: string[];
  dictionary: Set<string>;
  incrementalTimeMs: number;
  minWordLength: number;
  scoringMethodology: ScoringMethodology;
  onComplete: (data: CompleteGameData) => void;
  onLeaderboardClick: () => void;
  onShare: (data: Omit<ShareData, 'date'>) => void;
  onReset: () => void;
}

const PageContainer = styled('div')<{ isDone: boolean }>(({ isDone }) => ({
  height: ['100vh', '100svh'],
  paddingTop: ['1vh', '1svh'],
  paddingBottom: ['10vh', '3svh'],
  display: 'grid',
  gridRowGap: ['2vh', '2svh'],
  gridTemplateRows: isDone ? 'auto auto 1fr auto 360px' : 'auto 1fr auto 360px',
  gridTemplateColumns: '1fr 360px 1fr',
  '& > *': {
    gridColumn: 2,
  },
}));

const preventPullToRefreshStyles = (
  <Global
    styles={{
      ':root': {
        overflow: 'hidden',
        overscrollBehavior: 'contain',
      },
    }}
  />
);

const INACTIVE_PAUSE_TIME_MS = 15_000;

export const Game = ({
  missed: missedWords,
  found: foundWords,
  rotation,
  timer: savedTimer,
  addMissed,
  addFound,
  rotateCounterClockwise,
  rotateClockwise,
  setTimer,
  board,
  dictionary,
  incrementalTimeMs,
  minWordLength,
  scoringMethodology: methodology,
  onComplete,
  onLeaderboardClick,
  onShare,
  onReset,
}: Props) => {
  const [typedText, setTypedText] = useState('');
  const [wordIndices, setWordIndices] = useState<number[]>([]);
  const foundWordsSet = useMemo(() => new Set(foundWords), [foundWords]);
  const wordListRef = useRef<HTMLOListElement>(null);
  const word = useMemo(
    () => wordIndices.map((i) => board[i]).join('') || typedText,
    [board, typedText, wordIndices],
  );
  const statusColors = useWordStatusColors();
  const disabledColor = useThemeColor('gray.400', 'gray.500');
  const bodyBackground = useBodyBackground();

  const scoredSolo = useMemo(
    () =>
      scoreSolo(methodology, {
        user: { id: '', displayName: '' },
        found: foundWords,
        missed: missedWords,
      }),
    [foundWords, methodology, missedWords],
  );
  const {
    wordScores,
    play: { individualScore: score },
  } = scoredSolo;

  const scoreIncrements = useMemo(
    () => foundWords.map((w) => wordScores.get(w) ?? 0),
    [foundWords, wordScores],
  );
  const scoreIncrementDisplays = useMemo(
    () => scoreIncrements.map((inc) => `+${inc}`),
    [scoreIncrements],
  );

  const positiveTimeIncrements = useMemo(
    () =>
      scoreIncrements.map((s) => formatTimeIncrement(s * incrementalTimeMs)),
    [incrementalTimeMs, scoreIncrements],
  );
  const negativeTimeIncrements = useMemo(
    () =>
      range(0, missedWords.length)
        .map((i) => -getStrikeTimePenalty(methodology, i + 1))
        .filter(isTruthy)
        .map((value) => ({
          value,
          label: formatTimeIncrement(value),
        })),
    [methodology, missedWords.length],
  );

  const [timeRemaining, { isRunning, resume, pause, addTime, subtractTime }] =
    useTimer(
      savedTimer,
      () => {
        wordListRef.current!.scroll({
          left: 0,
          behavior: 'auto',
        });
        onComplete({
          score,
          found: foundWords,
          missed: missedWords,
        });
      },
      true,
    );

  const hasInteractedRef = useRef(false);
  if (!isRunning || wordIndices.length || typedText.length)
    hasInteractedRef.current = true;

  useTimer(INACTIVE_PAUSE_TIME_MS, () => {
    if (!hasInteractedRef.current) pause();
  });

  useWindowBlur(pause);

  const [isEndGameConfirmPending, endGameButtonProps] = useSecondClickConfirm(
    () => subtractTime(Infinity),
  );
  const displayTime = useMemo(
    () => formatDuration(timeRemaining, 'ceil'),
    [timeRemaining],
  );

  const isDone = !timeRemaining;
  const isPaused = !isRunning && !isDone;
  const wordStatus = getWordStatus(
    word,
    minWordLength,
    foundWordsSet,
    dictionary,
  );
  const isFound = foundWordsSet.has(word);
  const isMissed = missedWords.includes(word);
  const isValid = dictionary.has(word);

  const [glowStatus, setGlowStatus] = useState<WordStatus>();

  const foundWordsWithScore = useMemo(() => {
    const items = foundWords.map((word) => ({
      word,
      score: wordScores.get(word) ?? 0,
    }));

    return isDone
      ? orderBy(
          items,
          // Sorting by score and length is redundant since score is a function of
          // length, but this future proofs against changing the scoring method.
          ['score', 'length', identity],
          ['desc', 'desc', 'asc'],
        )
      : items;
  }, [foundWords, isDone, wordScores]);

  const goofiness = useGoofiness();

  useEffect(() => {
    setTimer(timeRemaining);
  }, [setTimer, timeRemaining]);

  const allowKeyboard = !useIsTouchDevice();

  const handleWordEnd = () => {
    if (!isDone && !isFound && !isMissed && word.length >= minWordLength) {
      if (isValid) {
        addFound(word);
        addTime(scoreSoloWord(methodology, word) * incrementalTimeMs);

        setTimeout(() => {
          const wordList = wordListRef.current;

          if (wordList) {
            const scrollLeftMax = wordList.scrollWidth - wordList.clientWidth;

            wordList.scroll({
              left: scrollLeftMax,
              behavior: 'smooth',
            });
          }
        }, 0);
      } else if (!isExemptMiss(word)) {
        subtractTime(getStrikeTimePenalty(methodology, missedWords.length + 1));

        addMissed(word);
      }
    }

    if (wordStatus !== 'short') {
      setGlowStatus(wordStatus);
    }

    setTypedText('');
    setWordIndices([]);
  };

  return (
    <>
      <PageContainer isDone={isDone}>
        <HStack justify="space-between" align="center">
          <div
            onDoubleClick={
              process.env.NODE_ENV === 'development' ? onReset : undefined
            }
          >
            Score:{' '}
            <Box as="span" textAlign="right">
              {score}
              {!isDone && (
                <IncrementDisplay increments={scoreIncrementDisplays} />
              )}
            </Box>
          </div>
          <StrikeIndicator
            methodology={methodology}
            strikeCount={missedWords.length}
          />
          {isDone && (
            <IconButton
              size="xs"
              colorScheme="blue"
              icon={<IoMdShare />}
              aria-label="Share"
              onClick={() => {
                onShare({ score, words: foundWordsWithScore });
              }}
            />
          )}
          {isDone && (
            <Button size="xs" colorScheme="blue" onClick={onLeaderboardClick}>
              Leaderboard
            </Button>
          )}
          {!isDone && (
            <Button
              size="xs"
              colorScheme="blue"
              variant="outline"
              {...endGameButtonProps}
            >
              {isEndGameConfirmPending ? 'Confirm?' : 'End Game'}
            </Button>
          )}
          {!isDone && (
            <button
              type="button"
              onClick={() => (isPaused ? resume() : pause())}
            >
              <Stack direction="row" align="center" spacing={1}>
                <Box
                  minWidth={`${0.625 * displayTime.length}em`}
                  textAlign="right"
                >
                  {displayTime}
                  <IncrementDisplay increments={positiveTimeIncrements} />
                  <IncrementDisplay increments={negativeTimeIncrements} />
                </Box>
                {isPaused ? <FiPlayCircle /> : <FiPauseCircle />}
              </Stack>
            </button>
          )}
          <ColorModeToggle />
        </HStack>
        {isDone && (
          <Box margin="auto">
            <NextGame />
          </Box>
        )}
        <PauseCover isPaused={isPaused}>
          <WordList ref={wordListRef} isReadOnly={isDone} height="100%">
            {useMemo(
              () =>
                foundWordsWithScore.map(({ word, score }) => (
                  <WordListItem key={word}>
                    {word}{' '}
                    <Box as="span" color={disabledColor}>
                      {score}
                    </Box>
                  </WordListItem>
                )),
              [disabledColor, foundWordsWithScore],
            )}
          </WordList>
        </PauseCover>
        <HStack justify="space-between" align="center">
          <IconButton
            variant="ghost"
            size="lg"
            icon={<FiRotateCcw />}
            isDisabled={isPaused}
            aria-label="rotate board counterclockwise"
            onClick={rotateCounterClockwise}
          />
          {allowKeyboard && !wordIndices.length && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleWordEnd();
              }}
            >
              <FocusLock disabled={isPaused}>
                <PauseCover
                  strength={0.5}
                  isPaused={isPaused}
                  overflow="visible"
                >
                  <Input
                    type="text"
                    value={typedText}
                    fontSize="3xl"
                    textAlign="center"
                    background={bodyBackground}
                    color={isFound ? statusColors[wordStatus] : undefined}
                    isDisabled={isPaused}
                    onChange={(e) =>
                      setTypedText(
                        e.target.value.replaceAll(/[^A-Z]/gi, '').toUpperCase(),
                      )
                    }
                  />
                </PauseCover>
              </FocusLock>
            </form>
          )}
          {wordIndices.length && (
            <Text
              color={isFound ? statusColors[wordStatus] : undefined}
              fontSize="3xl"
              overflow="visible"
            >
              {word}
            </Text>
          )}
          <IconButton
            variant="ghost"
            size="lg"
            icon={<FiRotateCw />}
            isDisabled={isPaused}
            aria-label="rotate board clockwise"
            onClick={rotateClockwise}
          />
        </HStack>
        <Center>
          <GameBoard
            board={board}
            rotation={rotation}
            goofiness={goofiness}
            isPaused={isPaused}
            onWordChange={setWordIndices}
            word={wordIndices}
            glowStatus={glowStatus}
            onWordEnd={handleWordEnd}
            onEndGlow={() => setGlowStatus(undefined)}
          />
        </Center>
      </PageContainer>
      {preventPullToRefreshStyles}
    </>
  );
};
