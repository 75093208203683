import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { BaseWordScoreTable } from './BaseWordScoreTable';
import { ScoreExplanation } from './ScoreExplanation';
import { ExplainableScore } from './useExplainableScore';

export const ExplainableScoreDisplay = (props: ExplainableScore) => {
  const { word, methodology } = props;

  return (
    <Tabs defaultIndex={1}>
      <TabList>
        <Tab>Base Scores</Tab>
        <Tab>{word}</Tab>
      </TabList>

      <TabPanels>
        <TabPanel maxW="400px">
          <BaseWordScoreTable methodology={methodology} />
        </TabPanel>
        <TabPanel>
          <ScoreExplanation {...props} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
