import { startOfDay } from 'date-fns';
import { range } from 'lodash';
import { DateString, parseDate } from 'shared';
import { getDailyStorageKey } from './getDailyStorageKey';
import { BulkPlay } from './serverInputs';
import { trpcClient } from './trpc';

const VERIFIED_NO_BACKLOG_TIMESTAMP_KEY = 'verified-no-backlog-timestamp';

const get = <T>(key: string, defaultValue?: T) => {
  const value = localStorage.getItem(key);

  if (typeof value === 'string') {
    return JSON.parse(value) as T;
  }

  if (defaultValue === undefined)
    throw new Error('Missing storage value and default value');

  return defaultValue;
};

const uploadBacklog = async (plays: BulkPlay[]) => {
  if (!plays.length) return;
  await trpcClient.play.bulkCreate.mutate({ plays });
};

const getCompletedLocalPlaysSince = (startDate: Date): BulkPlay[] => {
  const allKeys = range(localStorage.length).map((i) => localStorage.key(i)!);
  const allCompletedDates = allKeys.reduce<Date[]>((dates, key) => {
    const match = key.match(/^game-daily-(\d{4}-\d{2}-\d{2})-timer$/);

    if (match && get<number>(key) === 0) {
      const date = parseDate(match[1] as DateString);

      if (date > startDate) {
        dates.push(date);
      }
    }

    return dates;
  }, []);

  const plays = allCompletedDates.map((date): BulkPlay => {
    const key = getDailyStorageKey(date);

    return {
      date,
      found: get<string[]>(key('found'), []),
      missed: get<string[]>(key('missed'), []),
    };
  });

  return plays;
};

const avowNoBacklog = (hasUploadedToday: boolean) => {
  const timestamp = Math.max(
    // Avoid race condition between multiple calls to `checkBacklog` where a
    // `hasUploadedToday=false` call resolves after a `hasUploadedToday=true`
    // call.
    get(VERIFIED_NO_BACKLOG_TIMESTAMP_KEY, 0),
    hasUploadedToday ? Date.now() : startOfDay(Date.now()).valueOf() - 1,
  );

  localStorage.setItem(
    VERIFIED_NO_BACKLOG_TIMESTAMP_KEY,
    JSON.stringify(timestamp),
  );
};

export const checkBacklog = async (hasUploadedToday: boolean) => {
  const verifiedNoBacklogTimestamp = get<number | null>(
    VERIFIED_NO_BACKLOG_TIMESTAMP_KEY,
    null,
  );

  if (verifiedNoBacklogTimestamp === null) {
    const uploadedPlayCount = await trpcClient.play.count.query();

    const plays = getCompletedLocalPlaysSince(new Date(0));

    if (plays.length !== uploadedPlayCount) {
      await uploadBacklog(plays);
    }
  } else {
    await uploadBacklog(
      getCompletedLocalPlaysSince(new Date(verifiedNoBacklogTimestamp)),
    );
  }

  avowNoBacklog(hasUploadedToday);
};
