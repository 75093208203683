import { createStrictStatefulContext } from '@matt-tingen/react-hooks';
import { useMemo } from 'react';
import { localToUtc } from 'shared';
import { useOnce } from './useOnce';

export const { useDate: useLocalDate, DateProvider } =
  createStrictStatefulContext('Date', () => useOnce(() => new Date()));

/** Returns the beginning of the current date in UTC, similar to a `Temporal.PlainDate` */
export const useDate = () => {
  const local = useLocalDate();

  return useMemo(() => localToUtc(local), [local]);
};
