import { Box } from '@chakra-ui/react';
import {
  calculatePrevalenceRank,
  PrevalenceRank,
} from './calculatePrevalenceRank';
import { WordPrevalenceData } from './serverOutputs';

export interface PrevalenceExplanationProps {
  word: string;
  wordPrevalenceData: WordPrevalenceData;
}

const percentFormat = new Intl.NumberFormat(undefined, {
  style: 'percent',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const rarities: Record<Exclude<PrevalenceRank, 0>, string> = {
  1: 'rare',
  2: 'uncommon',
  3: 'common',
};

export const PrevalenceExplanation = ({
  word,
  wordPrevalenceData,
}: PrevalenceExplanationProps) => {
  const rank = calculatePrevalenceRank(word, wordPrevalenceData);

  if (rank === undefined) return null;

  if (rank === 0)
    return <>This is the only game in which this word has been found.</>;

  const count = wordPrevalenceData.wordCounts.get(word)!;
  const percentage = count / wordPrevalenceData.boardCount;
  const rarity = rarities[rank];

  return (
    <p>
      This word has been found in {count} games (
      {percentFormat.format(percentage)}
      ). Compared to other words, this is{' '}
      <Box as="span" fontWeight="bold">
        {rarity}
      </Box>
      .
    </p>
  );
};
