import { QueryClient, QueryObserverOptions } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { minutesToMilliseconds } from 'date-fns';
import { trpc } from './trpc';

const isProd = process.env.NODE_ENV === 'production';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: minutesToMilliseconds(1),
      refetchOnWindowFocus: isProd,
    },
  },
});

// Facilitate prefetching these queries
queryClient.setQueryDefaults(getQueryKey(trpc.play.list), {
  refetchOnMount: false,
});
queryClient.setQueryDefaults(getQueryKey(trpc.comment.list), {
  refetchOnMount: false,
});
queryClient.setQueryDefaults(getQueryKey(trpc.dailyBoard.find), {
  refetchOnMount: false,
});

export const indefinitelyValidOptions = {
  cacheTime: Infinity,
  staleTime: Infinity,
} satisfies QueryObserverOptions;

queryClient.setQueryDefaults(
  getQueryKey(trpc.changeset.list),
  indefinitelyValidOptions,
);
queryClient.setQueryDefaults(
  getQueryKey(trpc.dailyBoard.find),
  indefinitelyValidOptions,
);
