import { Dict } from '@matt-tingen/util';
import { partition } from 'lodash';
import { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { trpc } from './trpc';

const areSetsEqual = (left: Set<unknown>, right: Set<unknown>) =>
  new Set(left).symmetricDifference(new Set(right)).size === 0;

const emptySet = new Set<never>();

export const useAsyncDictionary = (date: Date, words: string[]) => {
  const [valid, setValid] = useState<ReadonlySet<string>>(emptySet);
  const [invalid, setInvalid] = useState<ReadonlySet<string>>(emptySet);

  const wordsSet = useMemo(() => new Set(words), [words]);
  const [debouncedWords] = useDebounce(wordsSet, 500, {
    equalityFn: areSetsEqual,
  });

  const unknownWords = useMemo(
    () => Array.from(debouncedWords.difference(valid).difference(invalid)),
    [debouncedWords, invalid, valid],
  );

  const { data: results } = trpc.dictionary.check.useQuery(
    {
      date,
      words: unknownWords,
    },
    { enabled: unknownWords.length > 0 },
  );

  const entries = results?.map(
    (isValid, i) => [unknownWords[i], isValid] as const,
  );
  const [validEntries, invalidEntries] = partition(
    entries,
    ([, isValid]) => isValid,
  );
  const newValid = validEntries.map(([word]) => word);
  const newInvalid = invalidEntries.map(([word]) => word);

  if (newValid.length) setValid((prev) => new Set([...prev, ...newValid]));
  if (newInvalid.length)
    setInvalid((prev) => new Set([...prev, ...newInvalid]));

  return useMemo(() => {
    const dict: Dict<boolean> = {};

    Array.from(valid).forEach((word) => {
      dict[word] = true;
    });
    Array.from(invalid).forEach((word) => {
      dict[word] = false;
    });

    return dict;
  }, [invalid, valid]);
};
