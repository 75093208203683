import {
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Slider,
  SliderThumb,
  SliderTrack,
  Stack,
  Theme,
  useTheme,
} from '@chakra-ui/react';
import { typedEntries } from '@matt-tingen/util';
import { useCallback, useMemo, useState } from 'react';
import { User } from 'shared';
import { ColorControl, ColorName, colorNames } from './ColorControl';
import { saturations as allSaturations, Saturation } from './colors';
import { useNickname } from './useNickname';
import { UserDisplay } from './UserDisplay';
import { useUserColor } from './useUserColor';
import { useUserId } from './useUserId';

export interface UserCustomizationFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit'> {
  onSubmit: (values: User) => void;
}

type ColorType = 'default' | 'custom';

const findColor = (colors: Theme['colors'], needle: string) => {
  const names = new Set<string>(colorNames);

  for (const [name, value] of typedEntries(colors)) {
    if (typeof value === 'object' && names.has(name)) {
      for (const [saturation, color] of typedEntries(value)) {
        if (needle === color)
          return {
            name: name as ColorName,
            saturation: Number(saturation) as Saturation,
          };
      }
    }
  }
};

// Exclude the extreme saturations since they're not very distinguishable.
const saturations = allSaturations.slice(2, -2);
const defaultSaturationIndex = Math.floor(saturations.length / 2);

const getSliderGradient = (theme: Theme, colorName: ColorName) => {
  const points = saturations
    .map((saturation, i) => {
      const color = theme.colors[colorName][saturation];
      const stop = Math.round((100 * i) / (saturations.length - 1));

      return `${color} ${stop}%`;
    })
    .join(', ');

  return [
    `linear-gradient(90deg, ${points})`,
    `linear-gradient(90deg in hsl, ${points})`,
  ];
};

export const UserCustomizationForm = ({
  onSubmit,
  ...formProps
}: UserCustomizationFormProps) => {
  const theme = useTheme();

  const userId = useUserId();
  const [savedUserName] = useNickname();
  const [savedColor] = useUserColor();
  const savedColorParts = useMemo(
    () => (savedColor ? findColor(theme.colors, savedColor) : undefined),
    [savedColor, theme.colors],
  );

  const [userName, setUserName] = useState(savedUserName);
  const [colorType, setColorType] = useState<ColorType>(
    savedColorParts ? 'custom' : 'default',
  );
  const [colorName, setColorName] = useState<ColorName>(
    savedColorParts?.name ?? 'red',
  );
  const [saturationIndex, setSaturationIndex] = useState(
    () =>
      (savedColorParts?.saturation &&
        saturations.indexOf(savedColorParts.saturation)) ??
      defaultSaturationIndex,
  );

  const saturation = saturations[saturationIndex];
  const color =
    colorType === 'default'
      ? undefined
      : (theme.colors[colorName][saturation] as string);
  const user: User = { id: userId, displayName: userName, color };

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      onSubmit({
        id: userId,
        displayName: userName,
        color,
      });
    },
    [color, onSubmit, userId, userName],
  );

  return (
    <form {...formProps} onSubmit={handleSubmit}>
      <Stack gap={4}>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            maxLength={20}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Color</FormLabel>

          <Stack gap={2}>
            <RadioGroup
              value={colorType}
              onChange={(value) => setColorType(value as ColorType)}
              display="flex"
              gap={4}
            >
              <Radio value={'default' satisfies ColorType}>Default</Radio>
              <Radio value={'custom' satisfies ColorType}>Custom</Radio>
            </RadioGroup>

            {colorType === 'custom' && (
              <>
                <ColorControl
                  saturation={saturation}
                  value={colorName}
                  onChange={setColorName}
                />
                <Slider
                  value={saturationIndex}
                  onChange={setSaturationIndex}
                  min={0}
                  max={saturations.length - 1}
                >
                  <SliderTrack
                    css={{
                      background: getSliderGradient(
                        theme as unknown as Theme,
                        colorName,
                      ),
                    }}
                    h={2}
                    rounded="full"
                  />
                  <SliderThumb
                    boxSize={6}
                    bg={color}
                    borderColor="white"
                    borderWidth={2}
                  />
                </Slider>
              </>
            )}
          </Stack>
        </FormControl>

        <FormControl>
          <FormLabel>Preview</FormLabel>
          <output>
            <UserDisplay user={user} />
          </output>
        </FormControl>
      </Stack>
    </form>
  );
};
