export const createJsonStorage = <T>(
  key: string,
  defaultValue: T,
  storage = localStorage,
) => {
  const load = () => {
    const stored = storage.getItem(key);

    return stored == null ? defaultValue : (JSON.parse(stored) as T);
  };

  const save = (value: T) => {
    storage.setItem(key, JSON.stringify(value));
  };

  return [load, save] as const;
};
