import { addDays } from 'date-fns';
import { boards } from './boards';

const DAY_0 = new Date(2022, 8, 8);

/** @deprecated */
export const getBoardDate = (board: string) => {
  const index = boards.indexOf(board);

  return index === -1 ? null : addDays(DAY_0, index);
};
