import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { withProse } from '@nikolovlazar/chakra-ui-prose';
import { QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import { App } from './App';
import { composeWrappers } from './composeWrappers';
import { DateProvider } from './DateProvider';
import { DevTools } from './DevTools';
import { ExplainableScoreWrapper } from './ExplainableScoreWrapper';
import { queryClient } from './reactQueryClient';
import { trpc, trpcReactClient } from './trpc';
import { WordInfoWrapper } from './WordInfoWrapper';

// https://github.com/emotion-js/emotion/issues/1105#issuecomment-557726922
const emotionCache = createCache({
  key: 'root',
  speedy: process.env.NODE_ENV !== 'development',
  // Disable prefixes in dev env.
  ...(process.env.NODE_ENV === 'development' && { stylisPlugins: [] }),
});

emotionCache.compat = true;

const theme = extendTheme(
  {},
  withProse({
    baseStyle: {
      p: {
        my: 4,
        '&:first-child': { mt: 0 },
        '&:last-child': { mb: 0 },
      },
      'h1,h2,h3,h4,h5': {
        '&:first-child': { mt: 0 },
      },
      'h5,h6': {
        fontWeight: 'bold',
      },
      h6: {
        fontSize: 'sm',
      },
    },
  }),
);

const Wrappers = composeWrappers(
  <CacheProvider value={emotionCache} />,
  <ChakraProvider theme={theme} />,
  DateProvider,
  <trpc.Provider
    queryClient={queryClient}
    client={trpcReactClient}
    // eslint-disable-next-line react/no-children-prop
    children={null}
  />,
  <QueryClientProvider client={queryClient} />,
  WordInfoWrapper,
  ExplainableScoreWrapper,
);

export const Root = () => (
  <StrictMode>
    <ColorModeScript initialColorMode="light" />
    <Wrappers>
      {process.env.REACT_QUERY_DEVTOOLS && <DevTools />}
      <App />
    </Wrappers>
  </StrictMode>
);
