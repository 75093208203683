import { Box, Center } from '@chakra-ui/react';
import { isTruthy, repeat } from '@matt-tingen/util';
import { last } from 'lodash';
import { useEffect, useMemo } from 'react';
import seed from 'seed-random';
import { getNeighboringIndices } from 'shared';
import { ColorDefinition, useThemeColor } from './colors';
import {
  useAdaptiveDiceHitBoxes,
  useShowDiceHitBoxes,
} from './FeatureFlagsForm';
import { Goofiness } from './goofiness';
import { usePrefersReducedMotion } from './usePrefersReducedMotion';
import { useSwipeHandler } from './useSwipeHandler';
import { titleCase } from './util';
import { useWordStatusColors, WordStatus } from './wordStatus';

interface Props {
  board: string[];
  rotation: number;
  isPaused: boolean;
  word: number[];
  onWordChange(indices: number[]): void;
  onWordEnd(): void;
  onEndGlow: () => void;
  glowStatus?: WordStatus;
  goofiness?: Partial<Goofiness>;
}

const DIE_WIDTH = 75;
const DICE_GAP = 20;
// A tolerance of 1 corresponds to a hit-test circle with diameter equal to
// `DIE_WIDTH`.
const SWIPE_TOLERANCE = 0.8;
const GLOW_IN_DURATION = 100;
const GLOW_OUT_DURATION = 800;
const ROTATION_TRANSFORM_TRANSITION =
  'transform 0.25s cubic-bezier(.6, 1.42, .9, .96)';

const hitboxColors: ColorDefinition[] = [
  'red.500',
  'teal.500',
  'yellow.500',
  'green.500',
  'purple.500',
  'orange.500',
  'cyan.500',
  'pink.500',
];

export const GameBoard = ({
  board,
  rotation,
  isPaused,
  word,
  onWordChange,
  onWordEnd,
  onEndGlow,
  glowStatus,
  goofiness,
}: Props) => {
  const rotateTransition = usePrefersReducedMotion()
    ? undefined
    : ROTATION_TRANSFORM_TRANSITION;

  const unselectedColor = useThemeColor('gray.400', 'whiteAlpha.500');
  const selectedColor = useThemeColor('gray.800', 'whiteAlpha.800');
  const statusColors = useWordStatusColors();
  const wordSet = useMemo(() => new Set(word), [word]);
  const tail = last(word);
  const size = Math.sqrt(board.length);
  const neighboringIndices = getNeighboringIndices(size);
  const neighbors = typeof tail === 'number' ? neighboringIndices[tail] : null;

  const svgViewBoxMin = -DICE_GAP / 2;
  const svgViewBoxWidth = size * (DIE_WIDTH + DICE_GAP);
  const svgViewBox = [
    svgViewBoxMin,
    svgViewBoxMin,
    svgViewBoxWidth,
    svgViewBoxWidth,
  ].join(' ');

  const showHitboxes = useShowDiceHitBoxes();
  const adaptiveDiceHitBoxes = useAdaptiveDiceHitBoxes();

  const dieNaturalRotations = useMemo(() => {
    const rand = seed(board.join(''));
    const randomRotation = () => Math.floor(rand() * 4) / 4;

    return repeat(randomRotation, board.length);
  }, [board]);

  const { containerHandlers } = useSwipeHandler({
    word,
    onWordChange,
    onWordEnd,
  });

  useEffect(() => {
    if (glowStatus) {
      const timer = setTimeout(() => {
        onEndGlow();
      }, GLOW_IN_DURATION);

      return () => clearTimeout(timer);
    }
  }, [glowStatus, onEndGlow]);

  return (
    <Box
      transform={[
        size === 4 ? undefined : `scale(${4 / size})`,
        goofiness?.skewBoard
          ? `translate(5%, -10%) perspective(800px) rotateX(40deg) rotateY(20deg) rotateZ(${rotation}turn)`
          : `rotate(${rotation}turn)`,
      ]
        .filter(isTruthy)
        .join('')}
      transformOrigin="center"
      transition={rotateTransition}
      display="grid"
      gridTemplateAreas="'overlap'"
      placeItems="center"
      sx={{ touchAction: 'none', '& > *': { gridArea: 'overlap' } }}
    >
      <Box
        as="svg"
        viewBox={svgViewBox}
        margin={`${svgViewBoxMin}px`}
        opacity="20%"
      >
        {board.map((char, i) => {
          return (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              as="g"
              fill={
                showHitboxes
                  ? hitboxColors[i % hitboxColors.length]
                  : 'transparent'
              }
              data-index={i}
            >
              {(() => {
                const width = DIE_WIDTH;
                const gap = DICE_GAP;
                const halfGap = DICE_GAP / 2;

                const row = Math.floor(i / size);
                const col = i % size;
                const minDieX = col * (width + gap);
                const minDieY = row * (width + gap);
                const maxDieX = minDieX + width;
                const maxDieY = minDieY + width;
                const centerX = minDieX + width / 2;
                const centerY = minDieY + width / 2;

                const minX = minDieX - halfGap;
                const minY = minDieY - halfGap;
                const maxX = maxDieX + halfGap;
                const maxY = maxDieY + halfGap;
                const span = width + gap;
                const halfSpan = span / 2;

                const fullRect = (
                  <rect
                    key="full-rect"
                    x={minX}
                    y={minY}
                    width={span}
                    height={span}
                  />
                );
                const exactRect = (
                  <rect
                    key="exact-rect"
                    x={minDieX}
                    y={minDieY}
                    width={width}
                    height={width}
                  />
                );
                const fullDiamond = (
                  <polyline
                    key="full-diamond"
                    points={[
                      `${centerX},${minY}`,
                      `${maxX},${centerY}`,
                      `${centerX},${maxY}`,
                      `${minX},${centerY}`,
                    ].join(' ')}
                  />
                );
                const innerCircle = (
                  <circle
                    key="inner-circle"
                    cx={centerX}
                    cy={centerY}
                    r={(width / 2) * SWIPE_TOLERANCE}
                  />
                );

                if (wordSet.size === 0) return fullRect;
                if (tail === i) return null;
                if (wordSet.has(i)) return innerCircle;
                if (!neighbors?.all.has(i)) return null;
                if (!adaptiveDiceHitBoxes) return innerCircle;

                const elements: JSX.Element[] = [
                  neighbors!.diagonals.has(i) ? exactRect : fullDiamond,
                ];

                const { compass } = neighbors!;
                const { edges } = neighboringIndices[i];
                const quadrantSize = { width: halfSpan, height: halfSpan };

                if (
                  edges.north ||
                  edges.east ||
                  [
                    compass.northWest,
                    compass.north,
                    compass.northEast,
                    compass.east,
                    compass.southEast,
                  ].includes(i)
                ) {
                  elements.push(
                    <rect
                      key="northeast"
                      x={centerX}
                      y={minY}
                      {...quadrantSize}
                    />,
                  );
                }

                if (
                  edges.south ||
                  edges.east ||
                  [
                    compass.northEast,
                    compass.south,
                    compass.southEast,
                    compass.east,
                    compass.southWest,
                  ].includes(i)
                ) {
                  elements.push(
                    <rect
                      key="southeast"
                      x={centerX}
                      y={centerY}
                      {...quadrantSize}
                    />,
                  );
                }

                if (
                  edges.south ||
                  edges.west ||
                  [
                    compass.northWest,
                    compass.west,
                    compass.southWest,
                    compass.south,
                    compass.southEast,
                  ].includes(i)
                ) {
                  elements.push(
                    <rect
                      key="southwest"
                      x={minX}
                      y={centerY}
                      {...quadrantSize}
                    />,
                  );
                }

                if (
                  edges.north ||
                  edges.west ||
                  [
                    compass.northEast,
                    compass.north,
                    compass.northWest,
                    compass.west,
                    compass.southWest,
                  ].includes(i)
                ) {
                  elements.push(
                    <rect
                      key="northwest"
                      x={minX}
                      y={minY}
                      {...quadrantSize}
                    />,
                  );
                }

                return elements;
              })()}
            </Box>
          );
        })}
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={`repeat(${size}, ${DIE_WIDTH}px)`}
        gridTemplateRows={`repeat(${size}, ${DIE_WIDTH}px)`}
        gap={`${DICE_GAP}px`}
        fontSize="50px"
        userSelect="none"
        pointerEvents="none"
      >
        {board.map((die, i) => {
          const isSelected = wordSet.has(i);
          const isTail = tail === i;
          const color = isSelected ? selectedColor : unselectedColor;
          const border = isTail ? 4 : 2;

          return (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={die + i}
              border={`${border}px`}
              borderRadius={8}
              fontWeight={isTail ? 'bold' : 'normal'}
              color={color}
              boxShadow={
                glowStatus && `0 0 15px 0px ${statusColors[glowStatus]}`
              }
              transition={
                glowStatus
                  ? `box-shadow ${GLOW_IN_DURATION}ms`
                  : `box-shadow ${GLOW_OUT_DURATION}ms`
              }
            >
              <Center
                position="relative"
                left={`${-border}px`}
                top={`${-border}px`}
                width={DIE_WIDTH}
                height={DIE_WIDTH}
              >
                {isPaused && (
                  <Box
                    transformOrigin="center"
                    transform={`rotate(${-rotation}turn)`}
                  >
                    ?
                  </Box>
                )}
                {!isPaused && (
                  <Box
                    transformOrigin="center"
                    transform={`rotate(${
                      goofiness?.rotateDice ? dieNaturalRotations[i] : -rotation
                    }turn)`}
                    transition={rotateTransition}
                  >
                    {titleCase(die)}
                  </Box>
                )}
              </Center>
            </Box>
          );
        })}
      </Box>
      <Box
        margin={`${svgViewBoxMin}px`}
        width={svgViewBoxWidth}
        height={svgViewBoxWidth}
        pointerEvents={isPaused ? 'none' : 'auto'}
        sx={{ touchAction: 'none' }}
        // An invisible background is used so that pointer events will trigger.
        background="black"
        opacity="0%"
        {...containerHandlers}
      />
    </Box>
  );
};
