import { startCase } from 'lodash';
import React, { ReactNode } from 'react';

export type ReactSetter<S> = React.Dispatch<React.SetStateAction<S>>;
export type Children = { children: ReactNode };

export const pad = (value: number) => value.toString().padStart(2, '0');

export const titleCase = (text: string) => startCase(text.toLowerCase());

export const isLetter = (text: string) => /^[A-Z]$/i.test(text);
