import { ScoreDrawer } from './ScoreDrawer';
import { ExplainableScoreProvider } from './useExplainableScore';
import { Children } from './util';

export const ExplainableScoreWrapper = ({ children }: Children) => {
  return (
    <ExplainableScoreProvider>
      {children}
      <ScoreDrawer />
    </ExplainableScoreProvider>
  );
};
