import {
  CreateTRPCClientOptions,
  createTRPCProxyClient,
  httpBatchLink,
  HTTPBatchLinkOptions,
  httpLink,
  HTTPLinkOptions,
  splitLink,
} from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import type { AppRouter, TrpcPaths } from 'server';
import superjson from 'superjson';
import { TRPC_URL } from './env';
import { CLIENT_SECRET, USER_ID } from './useUserId';

export const trpc = createTRPCReact<AppRouter>();

const authHeader = `Basic ${btoa(`${USER_ID}:${CLIENT_SECRET}`)}`;

const config = {
  url: TRPC_URL,
  headers: {
    Authorization: authHeader,
  },
} satisfies HTTPLinkOptions & HTTPBatchLinkOptions;

const nonBatchPaths = new Set<string>([
  'dailyBoard.find',
] satisfies TrpcPaths[]);

const clientConfig: CreateTRPCClientOptions<AppRouter> = {
  transformer: superjson,
  links: [
    splitLink({
      condition(op) {
        return nonBatchPaths.has(op.path) || op.context.batch !== false;
      },
      false: httpLink(config),
      true: httpBatchLink(config),
    }),
  ],
};

export const trpcReactClient = trpc.createClient(clientConfig);
export const trpcClient = createTRPCProxyClient(clientConfig);
