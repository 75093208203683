import { useLocalStorageValue } from '@react-hookz/web';
import { useCallback, useMemo } from 'react';
import { GameState } from './GameState';

export const useLocalGameState = (
  getKey: (stub: string) => string,
  initialTimeMs: number,
) => {
  const [missed, setMissed] = useLocalStorageValue<string[]>(
    getKey('missed'),
    [],
  );
  const [found, setFound] = useLocalStorageValue<string[]>(getKey('found'), []);
  const [rotation, setRotation] = useLocalStorageValue(getKey('rotation'), 0);
  const [timer, setTimer] = useLocalStorageValue(
    getKey('timer'),
    initialTimeMs,
  );

  const gameState = useMemo(
    (): GameState => ({ missed, found, rotation, timer }),
    [found, missed, rotation, timer],
  );

  const addMissed = useCallback(
    (word: string) => setMissed((prev) => [...prev, word]),
    [setMissed],
  );
  const addFound = useCallback(
    (word: string) => setFound((prev) => [...prev, word]),
    [setFound],
  );

  const rotateCounterClockwise = useCallback(
    () => setRotation((turn) => turn - 0.25),
    [setRotation],
  );
  const rotateClockwise = useCallback(
    () => setRotation((turn) => turn + 0.25),
    [setRotation],
  );

  const updaters = useMemo(
    () => ({
      addMissed,
      addFound,
      rotateCounterClockwise,
      rotateClockwise,
      setTimer,
    }),
    [addFound, addMissed, rotateClockwise, rotateCounterClockwise, setTimer],
  );

  return [gameState, updaters] as const;
};
