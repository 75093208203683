import { isValid } from 'date-fns';

/** An ISO-formatted date string. */
export type DateString =
  `${number}${number}${number}${number}-${number}${number}-${number}${number}`;

const pad = (value: number) => value.toString().padStart(2, '0');

export const formatDate = (date: Date) =>
  `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1)}-${pad(date.getUTCDate())}` as DateString;

export const parseDate = (string: DateString) => {
  const [year, month, date] = string.split('-').map(Number);

  return new Date(Date.UTC(year, month - 1, date));
};

export const utcToLocal = (utc: Date) =>
  new Date(utc.getUTCFullYear(), utc.getUTCMonth(), utc.getUTCDate());

export const localToUtc = (local: Date) =>
  new Date(Date.UTC(local.getFullYear(), local.getMonth(), local.getDate()));

export const isDateString = (value: string): value is DateString =>
  isValid(parseDate(value as DateString));
