import { FlyoutTextButton } from './FlyoutTextButton';
import { useWordInfo, WordInfo } from './useWordInfo';

export interface WordProps extends WordInfo {
  children?: React.ReactNode;
}

export const Word = ({ word, children = word, ...info }: WordProps) => {
  const [, setWordInfo] = useWordInfo();

  return (
    <FlyoutTextButton
      onClick={() => setWordInfo({ ...info, word: word.toLowerCase() })}
    >
      {children}
    </FlyoutTextButton>
  );
};
