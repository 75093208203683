import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { ScoringMethodology } from 'shared';

export interface BaseWordScoreTableProps {
  methodology: ScoringMethodology;
}

export const BaseWordScoreTable = ({
  methodology: { wordLengthScores },
}: BaseWordScoreTableProps) => {
  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>Word Length</Th>
          <Th>Base Score</Th>
        </Tr>
      </Thead>
      <Tbody>
        {wordLengthScores.map((score, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Tr key={i}>
            <Td>
              {3 + i}
              {i === wordLengthScores.length - 1 ? '+' : ''}
            </Td>
            <Td>{score}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
