import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { PrevalenceExplanation } from './PrevalenceExplanation';
import { useDefinitionWord } from './useDefinitionWord';
import { useWordInfo } from './useWordInfo';
import { WiktionaryAttribution } from './WiktionaryAttribution';
import { WiktionaryDefinitions } from './WiktionaryDefinitions';

export const WordInfoDrawer = () => {
  const [{ word, wordPrevalenceData } = {}, setWordInfo] = useWordInfo();
  const [, setWord] = useDefinitionWord();

  const prevalenceWord = word?.toUpperCase();
  // eslint-disable-next-line no-implicit-coercion
  const showPrevalence = !!(
    prevalenceWord && wordPrevalenceData?.wordCounts.has(prevalenceWord)
  );

  useEffect(() => {
    // Expose for development.
    Object.assign(window, {
      setWord,
    });
  }, [setWord, setWordInfo]);

  return (
    <Drawer
      isOpen={Boolean(word)}
      onClose={() => setWordInfo(undefined)}
      placement="bottom"
    >
      <DrawerOverlay />
      {word && (
        <DrawerContent maxH="75vh">
          <DrawerCloseButton />
          <DrawerHeader>{word}</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4} divider={<StackDivider />}>
              {showPrevalence && (
                <PrevalenceExplanation
                  word={prevalenceWord}
                  wordPrevalenceData={wordPrevalenceData!}
                />
              )}
              <WiktionaryDefinitions word={word} />
            </Stack>
          </DrawerBody>
          <DrawerFooter>
            <WiktionaryAttribution word={word} />
          </DrawerFooter>
        </DrawerContent>
      )}
    </Drawer>
  );
};
