import { isTruthy } from '@matt-tingen/util';
import { range } from 'lodash';
import { getBoardDate } from 'shared';
import { getDailyStorageKey } from '../getDailyStorageKey';

const get = localStorage.getItem.bind(localStorage);
const set = localStorage.setItem.bind(localStorage);

export const rekeyGameDataFromBoardToDate = () => {
  const allKeys = range(localStorage.length).map((i) => localStorage.key(i)!);
  const allBoards = allKeys
    .map((key) => key.match(/^([A-Z]{16}|[A-Z]{25})-timer$/)?.[1])
    .filter(isTruthy);

  const games = allBoards
    .map((board) => ({
      board,
      date: getBoardDate(board),
      timer: get(`${board}-timer`),
      found: get(`${board}-found`),
      missed: get(`${board}-missed`),
      rotation: get(`${board}-rotation`),
    }))
    .filter((game): game is typeof game & { date: Date } => Boolean(game.date));

  games.forEach((game) => {
    const key = getDailyStorageKey(game.date);

    if (game.timer !== null) set(key('timer'), game.timer);
    if (game.found !== null) set(key('found'), game.found);
    if (game.missed !== null) set(key('missed'), game.missed);
    if (game.rotation !== null) set(key('rotation'), game.rotation);
  });
};
